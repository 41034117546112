import React, { useState, useContext, useEffect } from 'react'
import StoreContext from '../../../context/StoreContext'
import { LineItemWrapper, PriceTop, PriceStyle, QuantityRow, QuantityInput, QuantityChangeButton, ImageWrapper, TopLine, ImageAreaContain, OptionsWrap } from './styles'
import { Price } from '../../Price';
import {
  BrowserView,
  MobileView
} from "react-device-detect";

const LineItem = props => {
  const { line_item } = props
  const {
    updateLineItem,
    removeLineItem,
    client,
    checkout
  } = useContext(StoreContext)

  const [quantity, setQuantity] = useState(line_item.quantity)

  const filteredTitle = line_item.title.replace('Copper Compression','');

  const variantImage = line_item.variant.image
    ? <img
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
      height='180px'
    />
    : null

  const selectedOptions = line_item.variant.selectedOptions
    ? line_item.variant.selectedOptions.map((option, index) => { 
      if (option.name !== 'Title') {
        return ( <h2 key={index}>{option.name}: {option.value}</h2> )      
      }
      return null
    })
    : null

  const handleRemove = () => {
    removeLineItem(client, checkout.id, line_item.id)
  }

  useEffect(() => {
    updateLineItem(client, checkout.id, line_item.id, quantity);
  }, [client, quantity, checkout.id, line_item.id, updateLineItem]);

  const decreaseQuantity = () => {
    setQuantity(quantity - 1);
  }

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  }

  const InnerPriceTag = ({ children }) => {
    return (
      <span className="compare-price">{children}</span>
    )
  }

  return (
    <LineItemWrapper>

      <TopLine>
        <ImageAreaContain>
          <ImageWrapper>{variantImage}</ImageWrapper>
        </ImageAreaContain>

        <PriceTop>
          <div>
            <h3 className="extra-title">Copper Compression</h3>
            <h1>{filteredTitle}</h1>
            <BrowserView>
              {selectedOptions}
            </BrowserView>
          </div>
          {/* This needs tag awareness - as we are using product tags to indicate exclusion from */}

          <PriceStyle>
            <Price variant={line_item.variant} tags={["test"]} TagWrapper='h3' TagInner={InnerPriceTag} />
          </PriceStyle>
        </PriceTop>
      </TopLine>

      <div className="text-wrapper">

        <MobileView>
          {selectedOptions}
        </MobileView>

        <OptionsWrap>
          <div className="option-row">
            <QuantityRow>
              <QuantityChangeButton
                type="button"
                onClick={() => decreaseQuantity()}
              >
                -
              </QuantityChangeButton>
                <QuantityInput
                  readOnly={true}
                  type="text"
                  id="quantity"
                  name="quantity"
                  value={quantity}
                />
              <QuantityChangeButton
                type="button"
                className="increase-change"
                onClick={() => increaseQuantity()}
              >
                +
              </QuantityChangeButton>
            </QuantityRow>
          </div>

          <button className="remove-item" onClick={handleRemove}>Remove</button>
        </OptionsWrap>
      </div>

    </LineItemWrapper>
  )
}

export default LineItem

import styled from '@emotion/styled'

import { oceanBlue, skyBlue, copper } from '../../constants/theme';


export const CheckoutButton = styled.button`
    margin-top: 30px;
    border: 1px solid white;
    border-radius: 3px;
    background-color: ${copper};
    color: white;
    padding: 18px 24px;
    font-size: 20px;
    &:hover {
      background-color: ${oceanBlue};
      cursor: pointer;
    }
`;

export const CartTotal = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 30px;
  text-align: right;
  h1 {
    font-size: 32px;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 12px;
  }

  h2 {
    margin: 0;
    font-size: 14px;

  }

  .remove-item {
    margin-top: 30px;
    border: 1px solid white;
    border-radius: 6px;
    background-color: ${skyBlue};
    color: white;
    padding: 14px 16px;
    font-size: 14px;
    &:hover {
      background-color: ${oceanBlue};
      cursor: pointer;
    }
  }

  .image-wrapper {
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
    width: 180px;
    background-color: white;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
`

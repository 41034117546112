import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'
import { lighten } from 'polished'
import { oceanBlue, skyBlue, skyBlue10, copper } from '../../../constants/theme';

export const QuantityRow = styled.div`
    display: flex;
    background-color: white;
    border-radius: 20px;
    margin-top: 6px;
    width: 100%;

  `;

export const QuantityChangeButton = styled.button`
    border: 1px solid ${lighten(0.40, skyBlue)};
    background-color: ${lighten(0.40, skyBlue)};
    font-size: 16px;
    padding: 8px 20px;
    transition: background-color 200ms;
    width: 140px;
    margin: 0;

    @media (max-width: ${breakpoints.m}px){
      width: 90px;
    }

    @media (max-width: ${breakpoints.s}px){
      width: 65px;
    }


    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: default;
    }

    &::-moz-focus-inner {
      border: none;
    }

    &.increase-change {
    }

    &:hover {
      transition: background-color 200ms;
      background-color: ${lighten(0.05, copper)};
      cursor: pointer;
      color: ${oceanBlue};
    }

    &:active {
      color: white;
      background-color: ${lighten(0.05, copper)};
    }

  `;

export const QuantityInput = styled.input`


    border: 1px solid ${lighten(0.40, skyBlue)};
    background-color: ${lighten(0.40, skyBlue)};

    border-radius: 0;
    font-size: 16px;
    padding: 9px 20px;
    text-align: center;
    width: calc(100% - 280px);

    @media (max-width: ${breakpoints.m}px){
      width: calc(100% - 180px);
    }

    @media (max-width: ${breakpoints.s}px){
      width: calc(100% - 130px);
    }


    input[type=text] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &:focus {
      outline: none;
    }

`;


export const ImageWrapper = styled.div`
    display: flex;
    width: 100px;
    height: 100px;
    background-color: white;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    img {
      border: 2px solid ${copper};
      width: 100px;
      height: 100px;
      object-fit: contain;
    }

    @media (max-width: ${breakpoints.m}px){
      width: 70px;
      height: 70px;

      img {
        border: 1px solid ${copper};
        width: 70px;
        height: 70px;
        object-fit: contain;
      }
    }
`;

export const LineItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${skyBlue10};
  margin-bottom: 30px;

  h1 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 9px;
    margin-bottom: 12px;
  }

  h2 {
    margin: 0;
    font-size: 13px;

  }

  .remove-item {
    border: 1px solid${skyBlue};
    border-radius: 0;
    background-color: ${skyBlue};
    color: white;
    padding: 9px 16px;
    font-size: 14px;
    height: 38px;

    &:hover {
      background-color: ${oceanBlue};
      cursor: pointer;
    }
  }

  .text-wrapper {

    width: 100%;

    h2 {
      margin-left: 160px;
      margin-bottom: 10px;

      @media (max-width: ${breakpoints.l}px){
        margin-left: 145px;
      }

      @media (max-width: ${breakpoints.m}px){
        margin-left: 114px;
      }

      @media (max-width: ${breakpoints.s}px){
        margin-left: 118px;
      }



    }
  }

`

export const PriceTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: top;
    width: 100%;

    @media (max-width: ${breakpoints.s}px){
      flex-direction: column;
    }

    .extra-title {
      @media (max-width: ${breakpoints.s}px){
        display: none;
      }
    }
`

export const PriceStyle = styled.div`
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      line-height: auto;
      font-size: 24px !important;
      text-align: right;

      @media (max-width: ${breakpoints.s}px){
        text-align: left;
      }


      span {
        color: ${skyBlue};
        display: block;
        text-decoration: line-through;
      }
    }
  `

export const TopLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;

  @media (max-width: ${breakpoints.l}px){
    padding: 1rem;
  }

  @media (max-width: ${breakpoints.s}px){
    align-items: flex-start;
  }



`

export const ImageAreaContain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  justify-content: flex-start;

  h3 {
    text-align: left;
  }

`

export const OptionsWrap = styled.div`

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  width: 100%;

  .option-row {
    width: 100%;
  }



`

import React, { useContext } from 'react'

import StoreContext from '../../context/StoreContext'

import { CartHeadContain, CartHeadTotal, CheckoutHeadButton, PriceButtonContain } from './styles'

const Cart = () => {
  const context = useContext(StoreContext)
  const { checkout } = context

  const handleCheckout = () => {

    let checkoutUrl = checkout.webUrl
    let gaLinkParam = '';

    if (process.env.NODE_ENV === `production` && typeof ga === `function`) {
      try {
        /*global ga*/
        gaLinkParam = '&' + ga.getAll()[0].get('linkerParam');
        checkoutUrl += gaLinkParam;
      } catch (error) {
        console.log('Google Analytics Not Present');
      }
    }

    window.open(checkoutUrl)

  }

  let totalItems = 0

  checkout.lineItems.forEach(line => { totalItems += line.quantity })

  return (
      <CartHeadContain>
        <CartHeadTotal>
          <div>{totalItems} Total Items</div>
          <PriceButtonContain>
            <h1>$ {checkout.totalPrice}</h1>
            <CheckoutHeadButton onClick={handleCheckout}>Check out</CheckoutHeadButton>
          </PriceButtonContain>
        </CartHeadTotal>
      </CartHeadContain>
  )
}

export default Cart

import styled from '@emotion/styled'
import { lighten, darken } from 'polished'

import { oceanBlue, copper, skyBlue } from '../../constants/theme';
import { breakpoints } from '../../utils/styles'


export const CartHeadContain = styled.div`
  background-color: ${lighten(0.05, copper)};
  padding: 7px 0;

  border-bottom: 2px solid ${darken(0.05, copper)};

  @media (max-width: ${breakpoints.m}px){
    padding: 7px 0;
  }

`

export const CheckoutHeadButton = styled.button`
  border: 1px solid white;
  border-radius: 3px;
  background-color: white;
  color: ${oceanBlue};
  padding: 4px 18px 5px 18px;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 20px;
  height: 30px;
  box-shadow: 2px 2px 3px rgba(0,0,0,0.3);

  @media (max-width: ${breakpoints.m}px){
    background-color: ${lighten(0.7, copper)};
  }

  &:hover {
    background-color: ${lighten(0.4, skyBlue)};
    color: black;
    cursor: pointer;
  }
`;


export const CartHeadTotal = styled.div`

  font-family: 'HKNova-Medium';
  font-weight: normal;


  text-shadow: 1px 1px 0px rgba(0,0,0,0.5);


  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  margin: auto;
  max-width: 1320px;
  padding: 0 40px;

  h1, h3 {
    font-size: 16px;
    margin: 0;
  }

  @media (max-width: ${breakpoints.m}px){
    padding: 0 10px 0 20px;

    h1, h3 {
      font-size: 14px;
    }

  }


`

export const PriceButtonContain = styled.div`
  display: flex;
  align-items: center;
`

import React, { useContext } from 'react'

import StoreContext from '../../context/StoreContext'
import LineItem from './LineItem'

import { CartTotal, CheckoutButton } from './styles'

// import queryString from 'query-string';

const Cart = () => {
  const context = useContext(StoreContext)
  const { checkout } = context

  const handleCheckout = () => {
    
    let checkoutUrl = checkout.webUrl
    let gaLinkParam = '';

    if (process.env.NODE_ENV === `production` && typeof ga === `function`) {
      try {
        /*global ga*/
        gaLinkParam = '&' + ga.getAll()[0].get('linkerParam');
        checkoutUrl += gaLinkParam;
      } catch (error) {
        console.log('Google Analytics Not Present');
      }
    }

    if (process.env.NODE_ENV === `production`) {
      try {
        const clickID = localStorage.getItem('impact_click_id')
        if(clickID) {
          checkoutUrl += '&irclickid=' + localStorage.getItem('impact_click_id')
        }
      } catch (error) {
        console.log('Error setting clickID');
      }
    }

    window.open(checkoutUrl)

  }

  const line_items = checkout.lineItems.map(line_item => {
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  return (
    <div>
      {line_items}
      <CartTotal>
        <h3>Total</h3>
        <h1>${checkout.totalPrice}</h1>
        <br />
        <CheckoutButton onClick={handleCheckout}>Check out</CheckoutButton>
      </CartTotal>
    </div>
  )
}

export default Cart

import React, { useContext } from 'react'
import StoreContext from '../context/StoreContext'
import { Link } from 'gatsby'
/* import CartBestSellers from '../components/CartBestSellers' */
import Cart from '../components/Cart'
import CartBar from '../components/CartBar'
import SEO from '../components/seo'
import ContactInfo from '../utils/contactInfo'
import { BasicPageContain, CopyBlockStyle, Grid, GridLeft, GridRight } from '../utils/copperTypography';
import {
  BrowserView,
  MobileView
} from "react-device-detect";

/* import {SalesBarStyle} from "../utils/styles"; */

/* const SaleBar = ({children}) => (
 *     <SalesBarStyle className='no-top'>
 *       <div className="interior">
 *         {children}
 *       </div>
 *     </SalesBarStyle>
 * )
 *  */


const CartPage = () => {

  const context = useContext(StoreContext)

  const { checkout } = context
  const hasItems = checkout.lineItems.length > 0;

  return (
    <BasicPageContain style={{marginTop: 0}}>
      <SEO title="Cart" />

      {hasItems && <CartBar /> }

      <CopyBlockStyle style={{paddingTop: 0}}>

        {/* <Grid>
            <CartBestSellers />
        </Grid> */}

        <Grid style={{marginTop: '20px'}}>
          <GridLeft>
            <h3>Copper Compression</h3>
            <h1 style={{marginBottom: 0}}>Your Cart</h1>
            <BrowserView>
              <h3>Contact information</h3>
              <ContactInfo />
            </BrowserView>
          </GridLeft>
          <GridRight>

            {!hasItems && <Link to="/collections/all-1" className="mt40">No Items in Cart - Go back to products</Link>}

            {hasItems &&
              <>
                <h3>ITEMS</h3>
                <Cart />
              </>
            }

            {/* <h3 className="mt40">Checkout Instructions</h3>
            <p className="wide">Copper Compression products utilize genuine copper ions (Cu+ and Cu++) rather than copper oxide (Cu2O). This is an important distinction because it means that we utilize the highest QUALITY copper. We believe that if you are buying copper-infused compression garments that you should get actual copper in your sleeves.</p> */}

            <MobileView>
              <h3>Contact information</h3>
              <ContactInfo />
            </MobileView>


            <Link to="/" className="mt40">Go back to the homepage</Link>

          </GridRight>
        </Grid>
      </CopyBlockStyle>
    </BasicPageContain>
  )
}

export default CartPage
